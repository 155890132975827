<template>
  <el-dialog
    title="设置期限"
    :visible.sync="dialogVisible"
    width="520px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCancel"
  >
    <el-form ref="paramsFormRef" :rules="rules" label-width="120px" :model="paramsForm">
      <el-form-item label="使用版" prop="type">
        <el-radio-group v-model="paramsForm.type">
          <el-radio :label="2">永久版</el-radio>
          <el-radio :label="1">期限版</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="使用到期时间" prop="limitTime" v-if="paramsForm.type === 1">
        <el-date-picker
          type="date"
          placeholder="选择日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          v-model="paramsForm.limitTime"
          style="width: 100%;"></el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onConfirm">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { setDeadline } from '@/api/admin/institution'
export default {
  data(){
    return{
      dialogVisible: false,
      submitLoading: false,
      paramsForm:{
        type: 2,
        limitTime: '',
      },
      rules:{
        type:[
          { required: true, message: '请选择', trigger: 'change' }
        ],
        limitTime: [
          { type: 'string', required: true, message: '请选择日期', trigger: 'change' }
        ],
      }
    }
  },
  methods:{
    open(row) {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.paramsForm = Object.assign(this.paramsForm, {
          id: row.id,
          type: row.type,
          limitTime: row.limitTime,
        });
      })
    },
    onConfirm(){
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        // if(this.paramsForm.type === 2){
        //   this.paramsForm.limitTime = ''
        // }
        this.submitLoading = true
        setDeadline(this.paramsForm).then(() => {
          this.$message.success('编辑成功！');
          this.$emit('onSuccess', 1);
          this.onCancel();
        }).finally(() => {
          this.submitLoading = false;
        });
      });
    },
    onCancel(){
      this.dialogVisible = false
      this.$refs.paramsFormRef.resetFields();
    }
  }
}
</script>

<style scoped>

</style>
